<template>
  <ion-page>

    <!-- Page Content -->
    <ion-content>
      <!-- Header -->
      <ModalHeader page="booking-details" />
      <!-- Home Feed -->
      <div class="app-max-width">
        <!-- If User is Authorized -->
        <div v-if="isAuth === true">
          <ion-item>
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-textarea v-model="bookingDetails" placeholder="Details..."></ion-textarea>
          </ion-item> 
          <ion-item v-if="bookingDetails != null" lines="none">
            <div tabindex="0"></div><!-- bug fix for safari 14 -->
            <ion-button color="primary" @click.prevent="storeBooking()">Save</ion-button>
          </ion-item> 
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonItem, IonTextarea, IonButton } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import doAuth from '../../services/auth';
import ModalHeader from '../../components/ModalHeader.vue';
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'BookingDetails',
  components: {
    IonContent,IonPage, IonItem, IonTextarea, ModalHeader, IonButton
  },
  data() {
    return {
      isAuth: false
    }
  },
  beforeMount: function() {
    this.isAuth = doAuth()
  },
  setup() {
    const store = useStore();
    const bookingDetails = ref(null);
    const router = useRouter();

    function storeBooking() {
      store.commit('setBooking', bookingDetails.value)
      goBack()
    }

    function goBack() {
      router.go(-1)
    }

    return {
      bookingDetails, storeBooking, router  
    }
  }
});
</script>

<style scoped>

</style>